import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { PostService } from '../../../../../admin/modules/post/services/post.service';
import { SolutionService } from '../../../../../admin/modules/solution/services/solution.service';
import { ServiceService } from '../../../../../admin/modules/service/services/service.service';
import { PostModel } from '../../../../../admin/modules/post/classes/default/model/post.model';
import { ServiceModel } from '../../../../../admin/modules/service/classes/default/model/service.model';
import { SolutionModel } from '../../../../../admin/modules/solution/classes/default/model/solution.model';
import {
  ComponentsModule,
  FilterItemsType,
  FilterTypesEnum,
  PaginationRequest,
  TranslationModule,
} from '@ckmk/angular';
import { PostCardComponent } from '../../../posts/components/post-card/post-card.component';
import { ServiceCardComponent } from '../../../services/components/service-card/service-card.component';
import { SolutionCardComponent } from '../../../solutions/components/solution-card/solution-card.component';

@Component({
  selector: 'home',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    TranslationModule,
    PostCardComponent,
    ComponentsModule,
    ServiceCardComponent,
    SolutionCardComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnChanges, OnDestroy {
  protected posts: PostModel[] = [];

  protected services: ServiceModel[] = [];

  protected solutions: SolutionModel[] = [];

  public constructor(
    private readonly _self: ElementRef,
    private readonly _postService: PostService,
    private readonly _servService: ServiceService,
    private readonly _solutionService: SolutionService,
  ) {}

  public ngOnInit() {
    this._postService.postsQuery.onQuerySuccess((res) => {
      if (res) {
        this.posts = res.result;
      }
    });

    this._servService.servicesQuery.onQuerySuccess((res) => {
      if (res) {
        this.services = res.result;
      }
    });

    this._solutionService.solutionsQuery.onQuerySuccess((res) => {
      if (res) {
        this.solutions = res.result;
      }
    });

    this.loadPosts();

    this.loadServices();

    this.loadSolutions();
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  public ngOnDestroy() {
    this._postService.postsQuery.unsubscribe(true);
    this._servService.servicesQuery.unsubscribe(true);
    this._solutionService.solutionsQuery.unsubscribe(true);
  }

  protected loadPosts(): void {
    this._postService.fetchAll({
      pagination: this.defaultPagination(),
      includePicture: true,
      includeCategory: true,
      filters: this.defaultFilters(),
    });
  }

  protected loadServices(): void {
    this._servService.fetchAll({
      pagination: this.defaultPagination(),
      includePicture: true,
      includeCategory: true,
      filters: this.defaultFilters(),
    });
  }

  protected loadSolutions(): void {
    this._solutionService.fetchAll({
      pagination: this.defaultPagination(),
      includePicture: true,
      includeCategory: true,
      filters: this.defaultFilters(),
    });
  }

  protected defaultPagination(): PaginationRequest {
    return new PaginationRequest(1, 3);
  }

  protected defaultFilters(): FilterItemsType {
    return [
      {
        property: 'active',
        condition: FilterTypesEnum.EQUAL_TO,
        value: true,
      },
      {
        property: 'category.active',
        condition: FilterTypesEnum.EQUAL_TO,
        value: true,
      },
    ];
  }
}
