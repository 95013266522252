import { Injectable } from '@angular/core';
import { PostsRequestQuery } from '../classes/default/queries/posts.request.query';
import { PostCategoriesRequestQuery } from '../classes/category/queries/post.categories.request.query';
import {
  PostCategoryFetchAllPayload,
  PostFetchAllPayload,
} from '../types/types';
import { PostCategoryRequestQuery } from '../classes/category/queries/post.category.request.query';
import { PostCategoryRequestMutation } from '../classes/category/queries/post.category.request.mutation';
import { PostRequestQuery } from '../classes/default/queries/post.request.query';
import { PostRequestMutation } from '../classes/default/queries/post.request.mutation';
import { GraphqlRequestCallback } from '@ckmk/angular';
import { PostCategoryByRequestQuery } from '../classes/category/queries/post.category.by.request.query';
import { PostByRequestQuery } from '../classes/default/queries/post.by.request.query';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  public get postsQuery(): PostsRequestQuery {
    return this._postsQuery;
  }

  public get postQuery(): PostRequestQuery {
    return this._postQuery;
  }

  public get postByQuery(): PostByRequestQuery {
    return this._postByQuery;
  }

  public get postMutation(): PostRequestMutation {
    return this._postMutation;
  }

  public get categoriesQuery(): PostCategoriesRequestQuery {
    return this._categoriesQuery;
  }

  public get categoryQuery(): PostCategoryRequestQuery {
    return this._categoryQuery;
  }

  public get categoryByQuery(): PostCategoryByRequestQuery {
    return this._categoryByQuery;
  }

  public get categoryMutation(): PostCategoryRequestMutation {
    return this._categoryMutation;
  }

  public constructor(
    private readonly _postsQuery: PostsRequestQuery,
    private readonly _postQuery: PostRequestQuery,
    private readonly _postByQuery: PostByRequestQuery,
    private readonly _postMutation: PostRequestMutation,
    private readonly _categoriesQuery: PostCategoriesRequestQuery,
    private readonly _categoryQuery: PostCategoryRequestQuery,
    private readonly _categoryByQuery: PostCategoryByRequestQuery,
    private readonly _categoryMutation: PostCategoryRequestMutation,
  ) {}

  public fetchAll(payload?: PostFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._postsQuery.watchQuery(_payload);
  }

  public fetchPost(id: string, payload?: PostFetchAllPayload): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._postQuery.watchQuery(_payload);
  }

  public fetchPostBy(payload: PostFetchAllPayload): void {
    const _payload: any = { payload };

    this._postByQuery.watchQuery(_payload);
  }

  public savePost(data: any, callback?: GraphqlRequestCallback): void {
    this._postMutation.mutate({ data }, undefined, callback);
  }

  public fetchAllCategories(payload?: PostCategoryFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._categoriesQuery.watchQuery(_payload);
  }

  public fetchCategory(
    id: string,
    payload?: PostCategoryFetchAllPayload,
  ): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._categoryQuery.watchQuery(_payload);
  }

  public fetchCategoryBy(payload: PostCategoryFetchAllPayload): void {
    const _payload: any = { payload };

    this._categoryByQuery.watchQuery(_payload);
  }

  public saveCategory(data: any, callback?: GraphqlRequestCallback): void {
    this._categoryMutation.mutate({ data }, undefined, callback);
  }
}
