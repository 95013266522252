import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { HeadlineComponent } from '../../../../components/headline/headline.component';
import { HtmlMetaService, TranslationModule } from '@ckmk/angular';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'front-header',
  standalone: true,
  imports: [HeadlineComponent, TranslationModule],
  templateUrl: './front-header.component.html',
  styleUrl: './front-header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontHeaderComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public title!: any;

  @Input({
    required: false,
  })
  public bgImage?: string = '/img/front-header.jpg';

  @HostBinding('style.background-image')
  protected propertyBgImage!: string;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
  ) {}

  public ngOnInit() {
    this.propertyBgImage = `url('${this.bgImage}')`;

    this._setViewTitle();
  }

  public ngOnChanges({ bgImage, title }: SimpleChanges) {
    if (title && !title.firstChange) {
      this._setViewTitle();
    }

    if (bgImage && !bgImage.firstChange) {
      this.propertyBgImage = `url('${bgImage.currentValue}')`;
    }
  }

  private _setViewTitle(): void {
    this._htmlService.setViewTitle(this.title, `${environment.SITE_NAME} | `);
  }
}
