import { Route } from '@angular/router';
import { PostsComponent } from '../views/home/posts.component';
import { PostShowComponent } from '../views/show/post-show.component';
import { PostCategoryComponent } from '../views/category/post-category.component';

export default {
  path: 'posts',
  children: [
    {
      path: '',
      component: PostsComponent,
    },
    {
      path: 'page/:page',
      component: PostsComponent,
    },
    {
      path: ':category',
      children: [
        {
          path: '',
          component: PostCategoryComponent,
        },
        {
          path: 'page/:page',
          component: PostCategoryComponent,
        },
        {
          path: 'post/:post',
          component: PostShowComponent,
        },
      ],
    },
  ],
} as Route;
