import { gql } from 'apollo-angular';

export const PostCreateMutation = gql`
  mutation createPost($data: PostCreateInput!) {
    createPost(data: $data) {
      id
      category {
        id
        title
        description
      }
      cover {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      picture {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      pictures {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      slug
      title
      description
      shortDescription
      active
      createdAt
      updatedAt
      deletedAt
      createdBy
      updatedBy
    }
  }
`;
