import {
  AbstractDefaultPropsModel,
  TranslationColumnValueModel,
  UploadModel,
} from '@ckmk/angular';
import { PostCategoryModel } from '../../category/model/post.category.model';

export class PostModel extends AbstractDefaultPropsModel {
  /**
   * The post id
   * @protected
   */
  protected _id?: string;
  public get id(): string | undefined {
    return this._id;
  }
  public set id(id: string | undefined) {
    this._id = id;
  }

  /**
   * The post category
   * @protected
   */
  protected _category?: PostCategoryModel;
  public get category(): PostCategoryModel | undefined {
    return this._category;
  }
  public set category(category: any) {
    this._category = this.toInstance(PostCategoryModel, category);
  }

  /**
   * The post cover
   * @protected
   */
  protected _cover?: UploadModel;
  public get cover(): UploadModel | undefined {
    return this._cover;
  }
  public set cover(cover: any) {
    this._cover = this.toInstance(UploadModel, cover);
  }

  /**
   * The post picture
   * @protected
   */
  protected _picture?: UploadModel;
  public get picture(): UploadModel | undefined {
    return this._picture;
  }
  public set picture(picture: any) {
    this._picture = this.toInstance(UploadModel, picture);
  }

  /**
   * The post pictures
   * @protected
   */
  protected _pictures?: UploadModel[];
  public get pictures(): UploadModel[] {
    return this._pictures || [];
  }
  public set pictures(pictures: any[]) {
    this._pictures = (pictures || []).map(
      (pic: any) => this.toInstance(UploadModel, pic) as UploadModel,
    );
  }

  /**
   * The post slug
   * @protected
   */
  protected _slug?: string;
  public get slug(): string | undefined {
    return this._slug;
  }
  public set slug(slug: string | undefined) {
    this._slug = slug;
  }

  /**
   * The post title
   * @protected
   */
  protected _title?: TranslationColumnValueModel;
  public get title(): TranslationColumnValueModel | undefined {
    return this._title;
  }
  public set title(title: any | undefined) {
    this._title = this.toInstance(TranslationColumnValueModel, title);
  }

  /**
   * The post description
   * @protected
   */
  protected _description?: TranslationColumnValueModel;
  public get description(): TranslationColumnValueModel | undefined {
    return this._description;
  }
  public set description(description: any | undefined) {
    this._description = this.toInstance(
      TranslationColumnValueModel,
      description,
    );
  }

  /**
   * The post short description
   * @protected
   */
  protected _shortDescription?: TranslationColumnValueModel;
  public get shortDescription(): TranslationColumnValueModel | undefined {
    return this._shortDescription;
  }
  public set shortDescription(shortDescription: any | undefined) {
    this._shortDescription = this.toInstance(
      TranslationColumnValueModel,
      shortDescription,
    );
  }

  public constructor(data?: any) {
    super();

    Object.assign(this, data || {});
  }

  protected override onClone(): PostModel {
    return new PostModel({
      category: this._category,
      description: this._description,
      shortDescription: this._shortDescription,
    });
  }
}
