<ng-container>
  <div class="container-header">
    <div class="header-title">
      <headline
        [title]="title | trans"
        [label]="title | trans"
      />
    </div>
  </div>
</ng-container>
