<ng-container>
  <dashboard-header
    [viewName]="'posts'"
    [subTitle]="'List of' | trans: {
      items: ('Posts' | trans).toLowerCase(),
    }"
    [breadcrumb]="breadcrumbItems"
  >
    <btn-group>
      <btn
        [label]="'Add'"
        [icon]="icons.add"
        [iconPosition]="'after'"
        [path]="'/posts/create' | dashUrl"
      />
    </btn-group>
  </dashboard-header>

  <datatable
    [name]="'posts-list'"
    [type]="datatableType"
    [data]="posts"
    [itemsName]="'Posts'"
    [loading]="loading"
    [pagination]="pagination"
    (onPayload)="onPayload($event)"
    (onEvent)="onEvent($event)"
  />
</ng-container>
