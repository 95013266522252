<ng-container>
  @if (solutionItem) {
    <front-header
      [title]="solutionItem.title"
      [bgImage]="solutionItem.cover?.frontUrl()"
    />

    <front-section>
      <wysiwyg-content
        [content]="solutionItem.description"
      />

      @if (solutions && solutions.length > 0) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="4"
        >
          @for (serv of solutions; track $index) {
            <solution-card [item]="serv"/>
          }
        </flex-content>
      }
    </front-section>
  }
</ng-container>
