<ng-container>
  <div class="menu-front-container"
       [ngClass]="{
      'menu-close': !opened,
      'menu-open': opened,
    }"
  >
    <div
      class="menu-front-content max-h-[100%]"
    >
      <!-- Logo part with burger on phone -->
      <div
        class="menu-logo"
      >
        <a
          [title]="siteName"
          [routerLink]="'/'"
          [routerLinkActive]="'active'"
          class="image-container"
        >
          <img
            class="image-logo"
            src="/img/logo_website.png"
            alt="{{ siteName }}"
          />
        </a>

        <button
          class="burger-menu"
          (click)="toggleOpened()"
        >
          <fa-icon [icon]="opened ? icons.close : icons.bars" class="text-5xl"/>
        </button>
      </div>

      <!-- The menu items -->
      @if (solutions && services) {
        <div
          class="menu-navs-container"
          [ngClass]="{
            'nav-hidden': !isOpened(),
            'nav-visible': isOpened(),
          }"
        >
          <div class="menu-items">
            <menu-item-group [title]="'Accueil'" [link]="'/home'"/>

            <menu-item-group [title]="'À propos'" [link]="'/about'"/>

            @if (services.length) {
              <menu-item-group [title]="'Services'" [link]="'/services'" [items]="services"/>
            }

            @if (solutions.length) {
              <menu-item-group [title]="'Solutions'" [link]="'/solutions'" [items]="solutions"/>
            }

            <menu-item-group [title]="'Actualités'" [link]="'/posts'"/>

            <menu-item-group [title]="'RSE'" [link]="'/rse'"/>

            <menu-item-group [title]="'Contact'" [link]="'/contact'"/>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
