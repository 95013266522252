import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  ItemNotFound,
} from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { PostCategoryModel } from '../model/post.category.model';
import { PostCategoryByQuery } from '../../../queries/category/post.category.by.query';

@Injectable({
  providedIn: 'root',
})
export class PostCategoryByRequestQuery extends AbstractGraphqlRequest<
  PostCategoryModel | ItemNotFound
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return PostCategoryByQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let item = data.findOnePostCategoryBy;

    if (item) {
      return new PostCategoryModel(item);
    }

    return new ItemNotFound();
  }
}
