import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { PostModel } from '../../../../../admin/modules/post/classes/default/model/post.model';
import { PostService } from '../../../../../admin/modules/post/services/post.service';
import {
  ComponentsModule,
  FilterTypesEnum,
  PaginationRequest,
  TranslationModule,
} from '@ckmk/angular';
import { PostCardComponent } from '../../components/post-card/post-card.component';

@Component({
  selector: 'posts',
  standalone: true,
  imports: [
    FrontContentComponent,
    FrontSectionComponent,
    FrontHeaderComponent,
    ComponentsModule,
    PostCardComponent,
    TranslationModule,
  ],
  templateUrl: './posts.component.html',
  styleUrl: './posts.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PostsComponent implements OnInit, OnChanges, OnDestroy {
  @Input({
    required: true,
    transform: (value: any) => {
      value = parseInt(value);

      if (isNaN(value)) {
        return 1;
      }

      return value;
    },
  })
  public page: number = 1;

  protected posts: PostModel[] = [];

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: PostService,
  ) {}

  public ngOnInit() {
    this._service.postsQuery.onQuerySuccess((res) => {
      if (res) {
        this.posts = res.result;
      }
    });

    this.loadPosts();
  }

  public ngOnChanges({ page }: SimpleChanges): void {
    if (page && !page.firstChange) {
      this.loadPosts();
    }
  }

  public ngOnDestroy() {
    this._service.postsQuery.unsubscribe();
  }

  protected loadPosts(): void {
    this._service.fetchAll({
      includePicture: true,
      includeCategory: true,
      pagination: new PaginationRequest(this.page, 20),
      filters: [
        {
          property: 'active',
          condition: FilterTypesEnum.EQUAL_TO,
          value: true,
        },
      ],
    });
  }
}
