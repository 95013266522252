import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { PostModel } from '../model/post.model';
import { PostCreateMutation } from '../../../queries/default/post.create.mutation';
import { PostUpdateMutation } from '../../../queries/default/post.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class PostRequestMutation extends AbstractGraphqlRequest<PostModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id ? PostUpdateMutation : PostCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const postData = data.createPost || data.updatePost;

    if (postData) {
      return new PostModel(postData);
    }

    return null;
  }
}
