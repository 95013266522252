<ng-container>
  @if (link) {
    <a
      [class]="'item-content'"
      [routerLink]="link"
      [class.active]="isActive()"
    >
      {{ title }}
    </a>
  } @else {
    <span
      [class]="'item-content cursor-context-menu'"
    >
      {{ title }}
    </span>
  }

  @if (items && items.length) {
    <button class="arrow-button" (click)="toggleVisibleClick()" (mouseenter)="toggleVisibleHover(true)">
      <fa-icon [icon]="getArrowIcon()"/>
    </button>

    <div
      class="sub-items"
      [ngClass]="{
        'items-visible': visible,
        'items-hidden': !visible,
      }"
      (mouseleave)="toggleVisibleHover(false)"
    >
      <div class="back-container">
        <button class="back-button" (click)="toggleVisibleClick()">
          <fa-icon [icon]="icons.arrowBack"/>
        </button>
      </div>

      <div class="sub-items-content">
        @for (item of getItems(); track $index) {
          @if (item.constructor.name.endsWith('SolutionCategoryModel')) {
            <sol-cat-btn [category]="item"/>
          } @else if (item.constructor.name.endsWith('ServiceCategoryModel')) {
            <ser-cat-btn [category]="item"/>
          } @else {
            <a
              [title]="item.title"
              [routerLink]="item.link"
              [class.active]="isActive(item.link)"
              class="sub-item"
            >
              {{ item.title }}
            </a>
          }
        }
      </div>
    </div>
  }
</ng-container>
