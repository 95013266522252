import { DashboardRoutes } from '@ckmk/angular';
import { environment } from '../../../environments/environment';
import { LoginComponent } from '../../modules/admin/views/login/login.component';
import { DashboardComponent } from '../../modules/admin/views/dashboard/dashboard.component';
import SolutionDashboardRoutes from '../../modules/admin/modules/solution/routes/solution.dashboard.routes';
import ServiceDashboardRoutes from '../../modules/admin/modules/service/routes/service.dashboard.routes';
import PostDashboardRoutes from '../../modules/admin/modules/post/routes/post.dashboard.routes';

export default DashboardRoutes({
  hash: environment.ADMIN_HASH,
  loginComponent: LoginComponent,
  dashboardComponent: DashboardComponent,
  routes: [
    SolutionDashboardRoutes,
    ServiceDashboardRoutes,
    PostDashboardRoutes,
  ],
});
