import { AbstractData } from '../../../../../../../classes/abstract/data/abstract.data';

export class SolutionCategoryData extends AbstractData {
  protected override onClone(): SolutionCategoryData {
    return new SolutionCategoryData({
      leftColor: this._leftColor,
      rightColor: this._rightColor,
    });
  }
}
