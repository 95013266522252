import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PostCategoryModel } from '../../../classes/category/model/post.category.model';
import { Router } from '@angular/router';
import {
  BreadcrumbItems,
  ComponentsModule,
  DashboardModule,
  dashboardPath,
  FormBuilderModule,
  FormPropErrorTranslatable,
  FormSubmitEmitterType,
  ItemNotFound,
  TabItems,
  TranslationModule,
  when,
} from '@ckmk/angular';
import { PostCategoryFormType } from '../../../classes/category/form/post.category.form.type';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { PostService } from '../../../services/post.service';

@Component({
  selector: 'app-post-category-manage',
  imports: [
    TranslationModule,
    DashboardModule,
    FormBuilderModule,
    ComponentsModule,
  ],
  templateUrl: './post-category-manage.component.html',
  styleUrl: './post-category-manage.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PostCategoryManageComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentInit
{
  @Input({
    required: false,
  })
  public set category(category: string | undefined) {
    if (category) {
      const currentValue = this._service.categoryQuery.current();

      if (
        !currentValue ||
        currentValue instanceof ItemNotFound ||
        currentValue.id !== category
      ) {
        this._service.fetchCategory(category, {
          includeCover: true,
        });
      } else {
        this.categoryItem = when(
          this.isCopy(),
          currentValue.clone(),
          currentValue,
        );

        this.initBreadcrumbItems(currentValue);
      }
    } else {
      this.initBreadcrumbItems();
    }
  }

  protected categoryItem?: PostCategoryModel;

  protected errors?: FormPropErrorTranslatable;

  protected tabs: TabItems = [
    {
      name: 'infos',
      label: 'Informations',
    },
    {
      name: 'description',
      label: 'Description',
    },
  ];

  protected icons = {
    save: faFloppyDisk,
  };

  protected breadcrumbItems!: BreadcrumbItems;

  protected loading: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: PostService,
    private readonly _router: Router,
    protected readonly formType: PostCategoryFormType,
  ) {}

  public ngOnInit() {
    this._service.categoryQuery.onQuerySuccess((result) => {
      if (result instanceof ItemNotFound) {
        this._router
          .navigate([dashboardPath('/posts/categories/')], {
            queryParams: { categoryNotFound: true },
          })
          .then();
      } else if (result) {
        this.categoryItem = when(
          this.isCopy(),
          result.clone(),
          when(this.isUpdate(), result),
        );

        this.initBreadcrumbItems(result);
      }
    });

    this._service.categoryMutation.onQuerySuccess(
      (result, loading, callback) => {
        if (loading) {
          this.loading = true;

          this.errors = undefined;
        } else if (result instanceof PostCategoryModel) {
          if (this.isUpdate()) {
            if (callback) {
              callback(() => {
                this._service.categoryQuery.change(result);

                this.initBreadcrumbItems(result);

                this.loading = false;
              });
            }
          } else {
            this._service.fetchAllCategories();

            this._router
              .navigate([dashboardPath('/posts/categories/update'), result.id])
              .then();
          }
        }
      },
    );

    this._service.categoryMutation.onQueryFailed((err, callback) => {
      if (err) {
        if (callback) {
          callback();
        }

        this.errors = err.details.messages;

        this.loading = false;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {
    this._service.categoryQuery.unsubscribe();
    this._service.categoryMutation.unsubscribe(true);
  }

  public ngAfterContentInit() {}

  protected isCreate(): boolean {
    return !this.isUpdate() && !this.isCopy();
  }

  protected isCopy(): boolean {
    return this._router.url.includes('/copy/');
  }

  protected isUpdate(): boolean {
    return this._router.url.includes('/update/');
  }

  protected isReady(): boolean {
    if (this.isCopy() || this.isUpdate()) {
      return !!this.categoryItem;
    }

    return true;
  }

  protected initBreadcrumbItems(item?: PostCategoryModel): void {
    const breadcrumbItems: BreadcrumbItems = [
      {
        label: 'Posts',
        path: '/posts/list',
      },
      {
        label: 'Categories',
        path: '/posts/categories/list',
      },
      {
        label: 'Create',
      },
    ];

    if (!this.isCreate() && item) {
      if (this.isCopy()) {
        breadcrumbItems[2].label = 'Copy';
      } else {
        breadcrumbItems[2].label = 'Update';
      }

      breadcrumbItems.push({
        label: item.title?.toString(),
      });
    }

    this.breadcrumbItems = breadcrumbItems;
  }

  protected getSubTitle(): string {
    if (this.isCopy()) {
      return 'Copy category';
    } else if (this.isUpdate()) {
      return 'Update category';
    }

    return 'Create category';
  }

  protected onSave(event: FormSubmitEmitterType): void {
    const data: any = event.data[event.name];

    if (this.isUpdate()) {
      data.id = this.categoryItem?.id;
    }

    this._service.saveCategory(data, event.enable);
  }
}
