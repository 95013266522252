<ng-container>
  <front-content>
    <front-header
      [title]="'News' | trans"
    />

    <front-section>
      <flex-content
        [direction]="'row'"
        [tabletColumns]="2"
        [computerColumns]="3"
      >
        @for (post of posts; track $index) {
          <post-card [item]="post"/>
        }
      </flex-content>
    </front-section>

  </front-content>
</ng-container>
