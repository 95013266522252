<ng-container>
  @defer (when isReady()) {
    <dashboard-header
      [viewName]="'service-categories'"
      [subTitle]="getSubTitle() | trans: {
        title: serviceItem?.title,
      }"
      [breadcrumb]="breadcrumbItems"
    />

    <form-group
      [name]="'service-category'"
      [type]="formType"
      [data]="serviceItem"
      [errors]="errors"
      [disableButtons]="true"
      (onSubmit)="onSave($event)"
    >
      <dashboard-card>
        <flex-content>
          <form-row [target]="'active'"/>

          <tabs-container
            [items]="tabs"
            [disabled]="loading"
          >
            <tab-content [name]="'infos'">
              <form-row [target]="'title'"/>

              <form-row [target]="'slug'"/>

              <form-row [target]="'category'"/>

              <flex-content
                [direction]="'row'"
                [tabletColumns]="2"
              >
                <form-row [target]="'picture'"/>

                <form-row [target]="'cover'"/>
              </flex-content>
            </tab-content>

            <tab-content [name]="'shortDescription'">
              <form-row [target]="'shortDescription'"/>
            </tab-content>

            <tab-content [name]="'description'">
              <form-row [target]="'description'"/>
            </tab-content>
          </tabs-container>


          <div class="form-buttons">
            <form-button
              [label]="'Save' | trans"
              [icon]="icons.save"
            />
          </div>
        </flex-content>
      </dashboard-card>
    </form-group>
  } @loading (minimum 500) {
    <div>Form loading</div>
  }
</ng-container>
