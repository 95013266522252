import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  ItemNotFound,
} from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionModel } from '../model/solution.model';
import { SolutionByQuery } from '../../../queries/default/solution.by.query';

@Injectable({
  providedIn: 'root',
})
export class SolutionByRequestQuery extends AbstractGraphqlRequest<
  SolutionModel | ItemNotFound
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionByQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let item = data.findOneSolutionBy;

    if (item) {
      return new SolutionModel(item);
    }

    return new ItemNotFound();
  }
}
