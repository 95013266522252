import { Injectable } from '@angular/core';
import { AbstractGraphqlRequest, GraphqlRequestType } from '@ckmk/angular';
import { TypedDocumentNode } from '@apollo/client';
import { PostCategoryModel } from '../model/post.category.model';
import { PostCategoryCreateMutation } from '../../../queries/category/post.category.create.mutation';
import { PostCategoryUpdateMutation } from '../../../queries/category/post.category.update.mutation';

@Injectable({
  providedIn: 'root',
})
export class PostCategoryRequestMutation extends AbstractGraphqlRequest<PostCategoryModel> {
  protected override type: GraphqlRequestType = 'mutation';

  protected override getQuery(payload: any): TypedDocumentNode<any, any> {
    return payload.data.id
      ? PostCategoryUpdateMutation
      : PostCategoryCreateMutation;
  }

  protected override onParseResult(data: any, payload?: any) {
    const categoryData = data.createPostCategory || data.updatePostCategory;

    if (categoryData) {
      return new PostCategoryModel(categoryData);
    }

    return null;
  }
}
