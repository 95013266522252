<ng-container>
  <front-content>
    <front-header
      [title]="'Contact'"
    />

    <front-section>
      <form-group
        [name]="'contact_form'"
        [type]="formType"
      >

        <form-footer>
          <form-button
            [label]="'Envoyer votre message'"
            [icon]="icons.send"
            [iconPosition]="'after'"
          />
        </form-footer>
      </form-group>
    </front-section>

  </front-content>
</ng-container>
