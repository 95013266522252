import { gql } from 'apollo-angular';

export const PostCategoriesQuery = gql`
  query findAllPostCategories($payload: PostCategoryRequestPayloadInput) {
    findAllPostCategories(payload: $payload) {
      pagination {
        hasNext
        hasPrevious
        limit
        page
        total
        totalPage
      }
      result {
        id
        cover {
          id
          name
          title
          description
          frontUrls
          isImage
        }
        slug
        title
        description
        active
        createdAt
        updatedAt
        deletedAt
        createdBy
        updatedBy
      }
    }
  }
`;
