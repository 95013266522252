import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  PaginationResult,
} from '@ckmk/angular';
import { ServiceFetchAllPayload, ServicesResult } from '../../../types/types';
import { TypedDocumentNode } from '@apollo/client';
import { ServiceModel } from '../model/service.model';
import { ServicesQuery } from '../../../queries/default/services.query';

@Injectable({
  providedIn: 'root',
})
export class ServicesRequestQuery extends AbstractGraphqlRequest<
  ServicesResult,
  { payload: ServiceFetchAllPayload }
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return ServicesQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let { pagination, result } = data.findAllServices;

    if (pagination) {
      pagination = new PaginationResult(pagination);
    }

    result = result.map((grp: any) => new ServiceModel(grp));

    return { result, pagination };
  }
}
