<ng-container>
  <img
    [src]="item.picture?.frontUrl()"
    [alt]="item.title"
    class="card-item-img"
  />

  <div class="card-item-title">
    {{ item.title }}
  </div>

  <div class="card-item-description">
    <wysiwyg-content
      [content]="item.shortDescription"
    />
  </div>

  <div class="card-item-see-more">
    <a
      [routerLink]="['/services', item.category?.slug, 'service', item.slug]"
    >
      <span>
        {{ 'See more' | trans }}
      </span>

      <fa-icon [icon]="icons.seeMore"/>
    </a>
  </div>
</ng-container>
