<ng-container>
  @if (serviceItem) {
    <front-header
      [title]="serviceItem.title"
      [bgImage]="serviceItem.cover?.frontUrl()"
    />

    <front-section>
      <wysiwyg-content
        [content]="serviceItem.description"
      />

      @if (services && services.length > 0) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="4"
        >
          @for (serv of services; track $index) {
            <service-card [item]="serv"/>
          }
        </flex-content>
      }
    </front-section>
  }
</ng-container>
