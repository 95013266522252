import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BreadcrumbItems,
  ComponentsModule,
  DashboardModule,
  dashboardPath,
  FormBuilderModule,
  FormPropErrorTranslatable,
  FormSubmitEmitterType,
  ItemNotFound,
  TabItems,
  TranslationModule,
  when,
} from '@ckmk/angular';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { ServiceService } from '../../../services/service.service';
import { ServiceModel } from '../../../classes/default/model/service.model';
import { ServiceFormType } from '../../../classes/default/form/service.form.type';

@Component({
  selector: 'app-service-manage',
  imports: [
    TranslationModule,
    DashboardModule,
    FormBuilderModule,
    ComponentsModule,
  ],
  templateUrl: './service-manage.component.html',
  styleUrl: './service-manage.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceManageComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentInit
{
  @Input({
    required: false,
  })
  public set service(service: string | undefined) {
    if (service) {
      const currentValue = this._service.serviceQuery.current();

      if (
        !currentValue ||
        currentValue instanceof ItemNotFound ||
        currentValue.id !== service
      ) {
        this._service.fetchService(service, {
          includeCategory: true,
          includePicture: true,
          includeCover: true,
        });
      } else {
        this.serviceItem = when(
          this.isCopy(),
          currentValue.clone(),
          currentValue,
        );

        this.initBreadcrumbItems(currentValue);
      }
    } else {
      this.initBreadcrumbItems();
    }
  }

  protected serviceItem?: ServiceModel;

  protected errors?: FormPropErrorTranslatable;

  protected tabs: TabItems = [
    {
      name: 'infos',
      label: 'Informations',
    },
    {
      name: 'shortDescription',
      label: 'Short description',
    },
    {
      name: 'description',
      label: 'Description',
    },
  ];

  protected icons = {
    save: faFloppyDisk,
  };

  protected breadcrumbItems!: BreadcrumbItems;

  protected loading: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: ServiceService,
    private readonly _router: Router,
    protected readonly formType: ServiceFormType,
  ) {}

  public ngOnInit() {
    this._service.serviceQuery.onQuerySuccess((result) => {
      if (result instanceof ItemNotFound) {
        this._router
          .navigate([dashboardPath('/services/')], {
            queryParams: { serviceNotFound: true },
          })
          .then();
      } else if (result) {
        this.serviceItem = when(
          this.isCopy(),
          result.clone(),
          when(this.isUpdate(), result),
        );

        this.initBreadcrumbItems(result);
      }
    });

    this._service.serviceMutation.onQuerySuccess(
      (result, loading, callback) => {
        if (loading) {
          this.loading = true;

          this.errors = undefined;
        } else if (result instanceof ServiceModel) {
          if (this.isUpdate()) {
            if (callback) {
              callback(() => {
                this._service.serviceQuery.change(result);

                this.initBreadcrumbItems(result);

                this.loading = false;
              });
            }
          } else {
            this._service.fetchAll();

            this._router
              .navigate([dashboardPath('/services/update'), result.id])
              .then();
          }
        }
      },
    );

    this._service.serviceMutation.onQueryFailed((err, callback) => {
      if (err) {
        if (callback) {
          callback();
        }

        this.errors = err.details.messages;

        this.loading = false;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {
    this._service.serviceQuery.unsubscribe();
    this._service.serviceMutation.unsubscribe(true);
  }

  public ngAfterContentInit() {}

  protected isCreate(): boolean {
    return !this.isUpdate() && !this.isCopy();
  }

  protected isCopy(): boolean {
    return this._router.url.includes('/copy/');
  }

  protected isUpdate(): boolean {
    return this._router.url.includes('/update/');
  }

  protected isReady(): boolean {
    if (this.isCopy() || this.isUpdate()) {
      return !!this.serviceItem;
    }

    return true;
  }

  protected initBreadcrumbItems(item?: ServiceModel): void {
    const breadcrumbItems: BreadcrumbItems = [
      {
        label: 'Services',
        path: '/services/list',
      },
      {
        label: 'Create',
      },
    ];

    if (!this.isCreate() && item) {
      if (this.isCopy()) {
        breadcrumbItems[1].label = 'Copy';
      } else {
        breadcrumbItems[1].label = 'Update';
      }

      breadcrumbItems.push({
        label: item.title?.toString(),
      });
    }

    this.breadcrumbItems = breadcrumbItems;
  }

  protected getSubTitle(): string {
    if (this.isCopy()) {
      return 'Copy service';
    } else if (this.isUpdate()) {
      return 'Update service';
    }

    return 'Create service';
  }

  protected onSave(event: FormSubmitEmitterType): void {
    const data: any = event.data[event.name];

    if (this.isUpdate()) {
      data.id = this.serviceItem?.id;
    }

    this._service.saveService(data, event.enable);
  }
}
