<ng-container>
  <front-content>
    <front-section [title]="'last posts' | trans">
      @if (posts.length) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="3"
        >
          @for (post of posts; track $index) {
            <post-card [item]="post"/>
          }
        </flex-content>
      }
    </front-section>

    <front-section [title]="'Our services' | trans">
      @if (services.length) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="3"
        >
          @for (service of services; track $index) {
            <service-card [item]="service"/>
          }
        </flex-content>
      }

      <div class="gray-headband outside-bottom">

      </div>
    </front-section>

    <front-section [title]="'Our solutions' | trans">
      @if (solutions.length) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="3"
        >
          @for (solution of solutions; track $index) {
            <solution-card [item]="solution"/>
          }
        </flex-content>
      }
    </front-section>

  </front-content>
</ng-container>
