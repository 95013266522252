import { Injectable } from '@angular/core';
import { SolutionsRequestQuery } from '../classes/default/queries/solutions.request.query';
import { SolutionCategoriesRequestQuery } from '../classes/category/queries/solution.categories.request.query';
import {
  SolutionCategoryFetchAllPayload,
  SolutionFetchAllPayload,
} from '../types/types';
import { SolutionCategoryRequestQuery } from '../classes/category/queries/solution.category.request.query';
import { SolutionCategoryRequestMutation } from '../classes/category/queries/solution.category.request.mutation';
import { SolutionRequestQuery } from '../classes/default/queries/solution.request.query';
import { SolutionRequestMutation } from '../classes/default/queries/solution.request.mutation';
import { GraphqlRequestCallback } from '@ckmk/angular';
import { SolutionByRequestQuery } from '../classes/default/queries/solution.by.request.query';
import { SolutionCategoryByRequestQuery } from '../classes/category/queries/solution.category.by.request.query';

@Injectable({
  providedIn: 'root',
})
export class SolutionService {
  public get solutionsQuery(): SolutionsRequestQuery {
    return this._solutionsQuery;
  }

  public get solutionQuery(): SolutionRequestQuery {
    return this._solutionQuery;
  }

  public get solutionByQuery(): SolutionByRequestQuery {
    return this._solutionByQuery;
  }

  public get solutionMutation(): SolutionRequestMutation {
    return this._solutionMutation;
  }

  public get categoriesQuery(): SolutionCategoriesRequestQuery {
    return this._categoriesQuery;
  }

  public get categoryQuery(): SolutionCategoryRequestQuery {
    return this._categoryQuery;
  }

  public get categoryByQuery(): SolutionCategoryByRequestQuery {
    return this._categoryByQuery;
  }

  public get categoryMutation(): SolutionCategoryRequestMutation {
    return this._categoryMutation;
  }

  public constructor(
    private readonly _solutionsQuery: SolutionsRequestQuery,
    private readonly _solutionQuery: SolutionRequestQuery,
    private readonly _solutionByQuery: SolutionByRequestQuery,
    private readonly _solutionMutation: SolutionRequestMutation,
    private readonly _categoriesQuery: SolutionCategoriesRequestQuery,
    private readonly _categoryQuery: SolutionCategoryRequestQuery,
    private readonly _categoryByQuery: SolutionCategoryByRequestQuery,
    private readonly _categoryMutation: SolutionCategoryRequestMutation,
  ) {}

  public fetchAll(payload?: SolutionFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._solutionsQuery.watchQuery(_payload);
  }

  public fetchSolution(id: string, payload?: SolutionFetchAllPayload): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._solutionQuery.watchQuery(_payload);
  }

  public fetchSolutionBy(payload: SolutionFetchAllPayload): void {
    const _payload: any = { payload };

    this._solutionByQuery.watchQuery(_payload);
  }

  public saveSolution(data: any, callback?: GraphqlRequestCallback): void {
    this._solutionMutation.mutate({ data }, undefined, callback);
  }

  public fetchAllCategories(payload?: SolutionCategoryFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._categoriesQuery.watchQuery(_payload);
  }

  public fetchCategory(
    id: string,
    payload?: SolutionCategoryFetchAllPayload,
  ): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._categoryQuery.watchQuery(_payload);
  }

  public fetchCategoryBy(payload: SolutionCategoryFetchAllPayload): void {
    const _payload: any = { payload };

    this._categoryByQuery.watchQuery(_payload);
  }

  public saveCategory(data: any, callback?: GraphqlRequestCallback): void {
    this._categoryMutation.mutate({ data }, undefined, callback);
  }
}
