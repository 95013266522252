import { Route } from '@angular/router';
import { SolutionsComponent } from '../views/home/solutions.component';
import { SolutionCategoryComponent } from '../views/category/solution-category.component';
import { SolutionShowComponent } from '../views/show/solution-show.component';

export default {
  path: 'solutions',
  children: [
    {
      path: '',
      component: SolutionsComponent,
    },
    {
      path: ':category',
      children: [
        {
          path: '',
          component: SolutionCategoryComponent,
        },
        {
          path: 'page/:page',
          component: SolutionCategoryComponent,
        },
        {
          path: 'solution/:solution',
          component: SolutionShowComponent,
        },
      ],
    },
  ],
} as Route;
