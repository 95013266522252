import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ComponentsModule,
  FilterTypesEnum,
  HtmlMetaService,
  ItemNotFound,
  PaginationRequest,
  TranslationModule,
  WysiwygModule,
} from '@ckmk/angular';
import { environment } from '../../../../../../../environments/environment';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { SolutionCardComponent } from '../../components/solution-card/solution-card.component';
import { SolutionModel } from '../../../../../admin/modules/solution/classes/default/model/solution.model';
import { SolutionService } from '../../../../../admin/modules/solution/services/solution.service';
import { Router } from '@angular/router';

@Component({
  selector: 'solution-show',
  standalone: true,
  imports: [
    FrontHeaderComponent,
    FrontSectionComponent,
    ComponentsModule,
    TranslationModule,
    SolutionCardComponent,
    WysiwygModule,
  ],
  templateUrl: './solution-show.component.html',
  styleUrl: './solution-show.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SolutionShowComponent implements OnInit, OnChanges, OnDestroy {
  @Input({
    required: true,
    alias: 'category',
  })
  public categorySlug!: string;

  @Input({
    required: true,
    alias: 'solution',
  })
  public slug!: string;

  protected solutionItem!: SolutionModel;

  protected solutions: SolutionModel[] = [];

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
    private readonly _service: SolutionService,
    private readonly _router: Router,
  ) {}

  public ngOnInit() {
    this._service.solutionByQuery.onQuerySuccess((res) => {
      if (res instanceof SolutionModel) {
        this.solutionItem = res;

        this._htmlService.setViewTitle(
          this.solutionItem.title?.toString() || '',
          environment.SITE_NAME,
        );

        this.loadSolutions();
      } else if (res instanceof ItemNotFound) {
        this._router.navigate(['/']).then();
      }
    });

    this._service.solutionsQuery.onQuerySuccess((res) => {
      if (res) {
        this.solutions = res.result;
      }
    });

    this.loadSolution();
  }

  public ngOnChanges({ slug, categorySlug }: SimpleChanges) {
    if (slug && !slug.firstChange) {
      this.loadSolution();
    }

    if (categorySlug && !categorySlug.firstChange) {
      this.loadSolution();
    }
  }

  public ngOnDestroy() {
    this._service.solutionByQuery.unsubscribe(
      this._service.solutionByQuery.current() instanceof ItemNotFound,
    );

    this._service.solutionsQuery.unsubscribe();
  }

  protected loadSolution(): void {
    if (this.slug && this.categorySlug) {
      const current = this._service.solutionByQuery.current();

      if (
        !current ||
        current instanceof ItemNotFound ||
        current.slug !== this.slug
      ) {
        this._service.fetchSolutionBy({
          includeCategory: true,
          includeCover: true,
          filters: [
            {
              property: 'active',
              condition: FilterTypesEnum.EQUAL_TO,
              value: true,
            },
            {
              property: 'slug',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.slug,
            },
            {
              property: 'category.slug',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.categorySlug,
            },
          ],
        });
      } else if (current) {
        this.solutionItem = current;

        this.loadSolutions();
      }
    }
  }

  protected loadSolutions(): void {
    if (this.solutionItem) {
      const current = this._service.solutionsQuery.current();

      if (this.canLoadSolutions(current?.result)) {
        this._service.fetchAll({
          includeCategory: true,
          includePicture: true,
          pagination: new PaginationRequest(1, 4),
          filters: [
            {
              property: 'active',
              condition: FilterTypesEnum.EQUAL_TO,
              value: true,
            },
            {
              property: 'category',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.solutionItem.category?.id,
            },
            {
              property: 'id',
              condition: FilterTypesEnum.NOT_EQUAL_TO,
              value: this.solutionItem.id,
            },
          ],
        });
      }
    }
  }

  protected canLoadSolutions(lastSolutions?: SolutionModel[]): boolean {
    if (this.solutionItem && lastSolutions) {
      return (
        lastSolutions.length === 0 ||
        lastSolutions.filter(
          (sol) =>
            sol.category?.id !== this.solutionItem.category?.id ||
            sol.id === this.solutionItem.id,
        ).length > 0
      );
    }

    return !lastSolutions;
  }
}
