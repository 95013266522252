import { gql } from 'apollo-angular';

export const SolutionsQuery = gql`
  query findAllSolutions($payload: SolutionRequestPayloadInput) {
    findAllSolutions(payload: $payload) {
      pagination {
        hasNext
        hasPrevious
        limit
        page
        total
        totalPage
      }
      result {
        id
        category {
          id
          title
          description
          shortDescription
          slug
        }
        cover {
          id
          name
          title
          description
          frontUrls
        }
        picture {
          id
          name
          title
          description
          frontUrls
        }
        slug
        title
        description
        shortDescription
        active
        createdAt
        updatedAt
        deletedAt
        createdBy
        updatedBy
      }
    }
  }
`;
