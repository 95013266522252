import { Route } from '@angular/router';
import { FrontComponent } from '../../modules/front/components/front/front.component';
import HomeRoutes from '../../modules/front/modules/home/routes/home.routes';
import AboutRoutes from '../../modules/front/modules/about/routes/about.routes';
import RseRoutes from '../../modules/front/modules/rse/routes/rse.routes';
import ContactRoutes from '../../modules/front/modules/contact/routes/contact.routes';
import PostsRoutes from '../../modules/front/modules/posts/routes/posts.routes';
import ServicesRoutes from '../../modules/front/modules/services/routes/services.routes';
import SolutionsRoutes from '../../modules/front/modules/solutions/routes/solutions.routes';
import NotFoundRoutes from '../../modules/front/modules/not-found/routes/not-found.routes';

export default {
  path: '',
  component: FrontComponent,
  children: [
    HomeRoutes,
    AboutRoutes,
    RseRoutes,
    ContactRoutes,
    PostsRoutes,
    ServicesRoutes,
    SolutionsRoutes,
    NotFoundRoutes,
  ],
} as Route;
