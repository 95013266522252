<ng-container>
  @if (categoryItem) {
    <front-content>
      <front-header
        [title]="categoryItem.title"
        [bgImage]="categoryItem.cover?.frontUrl()"
      />

      <front-section>
        <wysiwyg-content
          [content]="categoryItem.description"
        />

        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="3"
        >
          @if (solutions) {
            @for (solution of solutions; track $index) {
              <solution-card [item]="solution"/>
            }
          }
        </flex-content>
      </front-section>
    </front-content>
  }
</ng-container>
