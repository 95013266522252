import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  PaginationResult,
} from '@ckmk/angular';
import {
  ServiceCategoriesResult,
  ServiceCategoryFetchAllPayload,
} from '../../../types/types';
import { TypedDocumentNode } from '@apollo/client';
import { ServiceCategoriesQuery } from '../../../queries/category/service.categories.query';
import { ServiceCategoryModel } from '../model/service.category.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceCategoriesRequestQuery extends AbstractGraphqlRequest<
  ServiceCategoriesResult,
  { payload: ServiceCategoryFetchAllPayload }
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return ServiceCategoriesQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let { pagination, result } = data.findAllServiceCategories;

    if (pagination) {
      pagination = new PaginationResult(pagination);
    }

    result = result.map((grp: any) => new ServiceCategoryModel(grp));

    return { result, pagination };
  }
}
