import { Component } from '@angular/core';

@Component({
  selector: 'post-category',
  imports: [],
  templateUrl: './post-category.component.html',
  styleUrl: './post-category.component.scss'
})
export class PostCategoryComponent {

}
