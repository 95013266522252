import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  FilterTypesEnum,
  PaginationRequest,
  PlatformIdService,
} from '@ckmk/angular';
import { NgClass } from '@angular/common';
import { MenuItemGroupComponent } from '../menu-item-group/menu-item-group.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SolutionService } from '../../../admin/modules/solution/services/solution.service';
import { ServiceService } from '../../../admin/modules/service/services/service.service';
import { SolutionCategoryModel } from '../../../admin/modules/solution/classes/category/model/solution.category.model';
import { ServiceCategoryModel } from '../../../admin/modules/service/classes/category/model/service.category.model';

@Component({
  selector: 'menu-front',
  standalone: true,
  imports: [
    FaIconComponent,
    NgClass,
    MenuItemGroupComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './menu-front.component.html',
  styleUrl: './menu-front.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuFrontComponent implements OnInit, OnChanges {
  protected siteName: string = environment.SITE_NAME;

  protected icons = {
    bars: faBars,
    close: faXmark,
  };

  protected opened: boolean = false;

  protected solutions: SolutionCategoryModel[] | null = null;

  protected services: ServiceCategoryModel[] | null = null;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
    private readonly _solutionService: SolutionService,
    private readonly _serviceService: ServiceService,
  ) {}

  public ngOnInit() {
    this._solutionService.categoriesQuery.onQuerySuccess((response) => {
      if (response) {
        this.solutions = response.result;
      }
    });

    this._serviceService.categoriesQuery.onQuerySuccess((response) => {
      if (response) {
        this.services = response.result;
      }
    });

    // Load solution categories
    this._solutionService.fetchAllCategories({
      pagination: new PaginationRequest(0, 0),
      includeCover: true,
      filters: [
        {
          property: 'active',
          condition: FilterTypesEnum.EQUAL_TO,
          value: true,
        },
      ],
    });

    // Load service categories
    this._serviceService.fetchAllCategories({
      pagination: new PaginationRequest(0, 0),
      includeCover: true,
      filters: [
        {
          property: 'active',
          condition: FilterTypesEnum.EQUAL_TO,
          value: true,
        },
      ],
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  public toggleOpened(): void {
    this.opened = !this.opened;

    if (this._platformIdService.isBrowser()) {
      if (this.opened) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    }
  }

  protected isOpened(): boolean {
    return (
      this.opened ||
      (this._platformIdService.isBrowser() && document.body.offsetWidth >= 768)
    );
  }
}
