import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  PaginationResult,
} from '@ckmk/angular';
import { PostFetchAllPayload, PostsResult } from '../../../types/types';
import { TypedDocumentNode } from '@apollo/client';
import { PostModel } from '../model/post.model';
import { PostsQuery } from '../../../queries/default/posts.query';

@Injectable({
  providedIn: 'root',
})
export class PostsRequestQuery extends AbstractGraphqlRequest<
  PostsResult,
  { payload: PostFetchAllPayload }
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return PostsQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let { pagination, result } = data.findAllPosts;

    if (pagination) {
      pagination = new PaginationResult(pagination);
    }

    result = result.map((grp: any) => new PostModel(grp));

    return { result, pagination };
  }
}
