import {
  FieldTypesConfigList,
  FileAcceptTypesEnum,
  FilterTypesEnum,
  FormBuilderInterface,
  FormTypeInterface,
  PaginationRequest,
} from '@ckmk/angular';
import { Injectable } from '@angular/core';
import { SolutionModel } from '../model/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { environment } from '../../../../../../../../environments/environment';
import {
  DESCRIPTION_MAX_CHARS,
  DESCRIPTION_SHORT_MAX_CHARS,
} from '../../../../../../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SolutionFormType implements FormTypeInterface {
  public fields: FieldTypesConfigList = [];

  public constructor(private readonly _service: SolutionService) {}

  public async configure(
    builder: FormBuilderInterface<SolutionModel>,
  ): Promise<void> {
    // Prepare table
    const data = builder.getData();

    if (data?.id) {
      builder.add({
        type: 'choice',
        name: 'active',
        label: 'Enable',
        required: false,
        help: 'Enable help',
        config: {
          type: 'checkbox-boolean',
          buttonType: 'button-only',
        },
      });
    }

    builder
      .add({
        type: 'select',
        name: 'category',
        label: 'Category',
        required: true,
        config: {
          enableDefaultOption: false,
          options: async () => {
            return this._service.categoriesQuery
              .fetchQuery({
                payload: {
                  pagination: new PaginationRequest(0),
                  filters: [
                    {
                      property: 'active',
                      condition: FilterTypesEnum.EQUAL_TO,
                      value: true,
                    },
                  ],
                },
              })
              .then((res) => res?.result || [])
              .then((cats) =>
                cats.map(
                  (cat) =>
                    ({
                      label: cat.title,
                      value: cat.id,
                      description: cat.shortDescription,
                    }) as any,
                ),
              );
          },
        },
      })
      .add({
        type: 'file',
        name: 'cover',
        label: 'Cover',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'file',
        name: 'picture',
        label: 'Picture',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'translation',
        name: 'title',
        label: 'Title',
        required: true,
        config: {
          type: 'text',
          key: data?.title?.key,
          domain: data?.title?.domain || 'solutions',
        },
      })
      .add({
        type: 'default',
        name: 'slug',
        label: 'Slug',
        required: false,
        help: 'Slug explain',
        config: {
          type: 'text',
        },
      })
      .add({
        type: 'translation',
        name: 'description',
        label: 'Description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.description?.key,
          domain: data?.description?.domain || 'solutions',
          forceWysiwyg: true,
          max: DESCRIPTION_MAX_CHARS,
          licenseKey: environment.CKEDITOR5_LICENSE_KEY,
        },
      })
      .add({
        type: 'translation',
        name: 'shortDescription',
        label: 'Short description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.shortDescription?.key,
          domain: data?.shortDescription?.domain || 'solutions',
          forceWysiwyg: true,
          max: DESCRIPTION_SHORT_MAX_CHARS,
          licenseKey: environment.CKEDITOR5_LICENSE_KEY,
        },
      });
  }
}
