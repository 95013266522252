import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PipesModule, TranslationModule, WysiwygModule } from '@ckmk/angular';
import { RouterLink } from '@angular/router';
import { PostModel } from '../../../../../admin/modules/post/classes/default/model/post.model';

@Component({
  selector: 'post-card',
  standalone: true,
  imports: [
    FaIconComponent,
    PipesModule,
    RouterLink,
    TranslationModule,
    WysiwygModule,
  ],
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PostCardComponent implements OnInit, OnChanges {
  @Input({
    required: true,
  })
  public item!: PostModel;

  @HostBinding('className')
  protected className: string = 'card-item';

  protected icons = {
    seeMore: faArrowRight,
  };

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
