import {
  AbstractDefaultPropsModel,
  TranslationColumnValueModel,
  UploadModel,
} from '@ckmk/angular';
import { SolutionCategoryData } from '../data/solution.category.data';

export class SolutionCategoryModel extends AbstractDefaultPropsModel {
  /**
   * The category id
   * @protected
   */
  protected _id?: string;
  public get id(): string | undefined {
    return this._id;
  }
  public set id(id: string | undefined) {
    this._id = id;
  }

  /**
   * The category cover
   * @protected
   */
  protected _cover?: UploadModel;
  public get cover(): UploadModel | undefined {
    return this._cover;
  }
  public set cover(cover: any) {
    this._cover = this.toInstance(UploadModel, cover);
  }

  /**
   * The category slug
   * @protected
   */
  protected _slug?: string;
  public get slug(): string | undefined {
    return this._slug;
  }
  public set slug(slug: string | undefined) {
    this._slug = slug;
  }

  /**
   * The category title
   * @protected
   */
  protected _title?: TranslationColumnValueModel;
  public get title(): TranslationColumnValueModel | undefined {
    return this._title;
  }
  public set title(title: any | undefined) {
    this._title = this.toInstance(TranslationColumnValueModel, title);
  }

  /**
   * The category description
   * @protected
   */
  protected _description?: TranslationColumnValueModel;
  public get description(): TranslationColumnValueModel | undefined {
    return this._description;
  }
  public set description(description: any | undefined) {
    this._description = this.toInstance(
      TranslationColumnValueModel,
      description,
    );
  }

  /**
   * The solution short description
   * @protected
   */
  protected _shortDescription?: TranslationColumnValueModel;
  public get shortDescription(): TranslationColumnValueModel | undefined {
    return this._shortDescription;
  }
  public set shortDescription(shortDescription: any | undefined) {
    this._shortDescription = this.toInstance(
      TranslationColumnValueModel,
      shortDescription,
    );
  }

  /**
   * The category data
   * @protected
   */
  protected _data?: SolutionCategoryData;
  public get data(): SolutionCategoryData | undefined {
    return this._data;
  }
  public set data(data: any | undefined) {
    this._data = this.toInstance(SolutionCategoryData, data);
  }

  public constructor(data?: any) {
    super();

    Object.assign(this, data || {});
  }

  protected override onClone(): SolutionCategoryModel {
    return new SolutionCategoryModel({
      description: this.description?.clone(),
      shortDescription: this.shortDescription?.clone(),
      data: this.data?.clone(),
    });
  }
}
