import { Injectable } from '@angular/core';
import {
  AbstractGraphqlRequest,
  GraphqlRequestType,
  PaginationResult,
} from '@ckmk/angular';
import {
  SolutionCategoriesResult,
  SolutionCategoryFetchAllPayload,
} from '../../../types/types';
import { TypedDocumentNode } from '@apollo/client';
import { SolutionCategoriesQuery } from '../../../queries/category/solution.categories.query';
import { SolutionCategoryModel } from '../model/solution.category.model';

@Injectable({
  providedIn: 'root',
})
export class SolutionCategoriesRequestQuery extends AbstractGraphqlRequest<
  SolutionCategoriesResult,
  { payload: SolutionCategoryFetchAllPayload }
> {
  protected override type: GraphqlRequestType = 'query';

  protected override getQuery(): TypedDocumentNode<any, any> {
    return SolutionCategoriesQuery;
  }

  protected override onParseResult(data: any, payload?: any) {
    let { pagination, result } = data.findAllSolutionCategories;

    if (pagination) {
      pagination = new PaginationResult(pagination);
    }

    result = result.map((grp: any) => new SolutionCategoryModel(grp));

    return { result, pagination };
  }
}
