import { gql } from 'apollo-angular';

export const ServiceByQuery = gql`
  query findOneServiceBy($payload: ServiceRequestPayloadInput) {
    findOneServiceBy(payload: $payload) {
      id
      category {
        id
        title
        description
        slug
      }
      cover {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      picture {
        id
        name
        originalName
        extension
        active
        title
        description
        directory
        directories
        chunkEnd
        chunkStart
        frontUrls
        isImage
        mimetype
        temp
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      slug
      title
      description
      shortDescription
      active
      createdAt
      updatedAt
      deletedAt
      createdBy
      updatedBy
    }
  }
`;
