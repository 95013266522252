import { Injectable } from '@angular/core';
import { ServicesRequestQuery } from '../classes/default/queries/services.request.query';
import { ServiceCategoriesRequestQuery } from '../classes/category/queries/service.categories.request.query';
import {
  ServiceCategoryFetchAllPayload,
  ServiceFetchAllPayload,
} from '../types/types';
import { ServiceCategoryRequestQuery } from '../classes/category/queries/service.category.request.query';
import { ServiceCategoryRequestMutation } from '../classes/category/queries/service.category.request.mutation';
import { ServiceRequestQuery } from '../classes/default/queries/service.request.query';
import { ServiceRequestMutation } from '../classes/default/queries/service.request.mutation';
import { GraphqlRequestCallback } from '@ckmk/angular';
import { ServiceCategoryByRequestQuery } from '../classes/category/queries/service.category.by.request.query';
import { ServiceByRequestQuery } from '../classes/default/queries/service.by.request.query';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  public get servicesQuery(): ServicesRequestQuery {
    return this._servicesQuery;
  }

  public get serviceQuery(): ServiceRequestQuery {
    return this._serviceQuery;
  }

  public get serviceByQuery(): ServiceByRequestQuery {
    return this._serviceByQuery;
  }

  public get serviceMutation(): ServiceRequestMutation {
    return this._serviceMutation;
  }

  public get categoriesQuery(): ServiceCategoriesRequestQuery {
    return this._categoriesQuery;
  }

  public get categoryQuery(): ServiceCategoryRequestQuery {
    return this._categoryQuery;
  }

  public get categoryByQuery(): ServiceCategoryByRequestQuery {
    return this._categoryByQuery;
  }

  public get categoryMutation(): ServiceCategoryRequestMutation {
    return this._categoryMutation;
  }

  public constructor(
    private readonly _servicesQuery: ServicesRequestQuery,
    private readonly _serviceQuery: ServiceRequestQuery,
    private readonly _serviceByQuery: ServiceByRequestQuery,
    private readonly _serviceMutation: ServiceRequestMutation,
    private readonly _categoriesQuery: ServiceCategoriesRequestQuery,
    private readonly _categoryQuery: ServiceCategoryRequestQuery,
    private readonly _categoryByQuery: ServiceCategoryByRequestQuery,
    private readonly _categoryMutation: ServiceCategoryRequestMutation,
  ) {}

  public fetchAll(payload?: ServiceFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._servicesQuery.watchQuery(_payload);
  }

  public fetchService(id: string, payload?: ServiceFetchAllPayload): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._serviceQuery.watchQuery(_payload);
  }

  public fetchServiceBy(payload: ServiceFetchAllPayload): void {
    const _payload: any = { payload };

    this._serviceByQuery.watchQuery(_payload);
  }

  public saveService(data: any, callback?: GraphqlRequestCallback): void {
    this._serviceMutation.mutate({ data }, undefined, callback);
  }

  public fetchAllCategories(payload?: ServiceCategoryFetchAllPayload): void {
    const _payload = payload ? { payload: payload } : undefined;

    this._categoriesQuery.watchQuery(_payload);
  }

  public fetchCategory(
    id: string,
    payload?: ServiceCategoryFetchAllPayload,
  ): void {
    const _payload: any = { id };

    if (payload) {
      _payload.payload = payload;
    }

    this._categoryQuery.watchQuery(_payload);
  }

  public fetchCategoryBy(payload: ServiceCategoryFetchAllPayload): void {
    const _payload: any = { payload };

    this._categoryByQuery.watchQuery(_payload);
  }

  public saveCategory(data: any, callback?: GraphqlRequestCallback): void {
    this._categoryMutation.mutate({ data }, undefined, callback);
  }
}
