import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { ServiceModel } from '../../../../../admin/modules/service/classes/default/model/service.model';
import { TranslationModule, WysiwygModule } from '@ckmk/angular';

@Component({
  selector: 'service-card',
  standalone: true,
  imports: [FaIconComponent, RouterLink, TranslationModule, WysiwygModule],
  templateUrl: './service-card.component.html',
  styleUrl: './service-card.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceCardComponent implements OnInit, OnChanges {
  @Input({
    required: true,
  })
  public item!: ServiceModel;

  @HostBinding('className')
  protected className: string = 'card-item';

  protected icons = {
    seeMore: faArrowRight,
  };

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}
