<ng-container>
  @if (postItem) {
    <front-header
      [title]="postItem.title"
      [bgImage]="postItem.cover?.frontUrl()"
    />

    <front-section>
      @if (postItem.pictures && postItem.pictures.length) {
        <gallery [items]="postItem.pictures"/>
      }

      <wysiwyg-content
        [content]="postItem.description"
      />

      @if (posts && posts.length > 0) {
        <flex-content
          [direction]="'row'"
          [tabletColumns]="2"
          [computerColumns]="4"
        >
          @for (serv of posts; track $index) {
            <post-card [item]="serv"/>
          }
        </flex-content>
      }
    </front-section>
  }
</ng-container>
