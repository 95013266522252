import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ComponentsModule,
  FilterTypesEnum,
  HtmlMetaService,
  ItemNotFound,
  PaginationRequest,
  TranslationModule,
  WysiwygModule,
} from '@ckmk/angular';
import { environment } from '../../../../../../../environments/environment';
import { FrontHeaderComponent } from '../../../../components/front-header/front-header.component';
import { FrontSectionComponent } from '../../../../components/front-section/front-section.component';
import { ServiceModel } from '../../../../../admin/modules/service/classes/default/model/service.model';
import { ServiceService } from '../../../../../admin/modules/service/services/service.service';
import { Router } from '@angular/router';
import { ServiceCardComponent } from '../../components/service-card/service-card.component';

@Component({
  selector: 'service-show',
  standalone: true,
  imports: [
    FrontHeaderComponent,
    FrontSectionComponent,
    TranslationModule,
    ComponentsModule,
    ServiceCardComponent,
    WysiwygModule,
  ],
  templateUrl: './service-show.component.html',
  styleUrl: './service-show.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceShowComponent implements OnInit, OnChanges, OnDestroy {
  @Input({
    required: true,
    alias: 'category',
  })
  public categorySlug!: string;

  @Input({
    required: true,
    alias: 'service',
  })
  public slug!: string;

  protected serviceItem!: ServiceModel;

  protected services: ServiceModel[] = [];

  public constructor(
    private readonly _self: ElementRef,
    private readonly _htmlService: HtmlMetaService,
    private readonly _service: ServiceService,
    private readonly _router: Router,
  ) {}

  public ngOnInit() {
    this._service.serviceByQuery.onQuerySuccess((res) => {
      if (res instanceof ServiceModel) {
        this.serviceItem = res;

        this._htmlService.setViewTitle(
          this.serviceItem.title?.toString() || '',
          environment.SITE_NAME,
        );

        this.loadServices();
      } else if (res instanceof ItemNotFound) {
        this._router.navigate(['/']).then();
      }
    });

    this._service.servicesQuery.onQuerySuccess((res) => {
      if (res) {
        this.services = res.result;
      }
    });

    this.loadService();
  }

  public ngOnChanges({ slug, categorySlug }: SimpleChanges) {
    if (slug && !slug.firstChange) {
      this.loadService();
    }

    if (categorySlug && !categorySlug.firstChange) {
      this.loadService();
    }
  }

  public ngOnDestroy() {
    this._service.serviceByQuery.unsubscribe(
      this._service.serviceByQuery.current() instanceof ItemNotFound,
    );

    this._service.servicesQuery.unsubscribe();
  }

  protected loadService(): void {
    if (this.slug && this.categorySlug) {
      const current = this._service.serviceByQuery.current();

      if (
        !current ||
        current instanceof ItemNotFound ||
        current.slug !== this.slug
      ) {
        this._service.fetchServiceBy({
          includeCategory: true,
          includeCover: true,
          filters: [
            {
              property: 'active',
              condition: FilterTypesEnum.EQUAL_TO,
              value: true,
            },
            {
              property: 'slug',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.slug,
            },
            {
              property: 'category.slug',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.categorySlug,
            },
          ],
        });
      } else if (current) {
        this.serviceItem = current;

        this.loadServices();
      }
    }
  }

  protected loadServices(): void {
    if (this.serviceItem) {
      const current = this._service.servicesQuery.current();

      if (this.canLoadServices(current?.result)) {
        this._service.fetchAll({
          includeCategory: true,
          includePicture: true,
          pagination: new PaginationRequest(1, 4),
          filters: [
            {
              property: 'active',
              condition: FilterTypesEnum.EQUAL_TO,
              value: true,
            },
            {
              property: 'category',
              condition: FilterTypesEnum.EQUAL_TO,
              value: this.serviceItem.category?.id,
            },
            {
              property: 'id',
              condition: FilterTypesEnum.NOT_EQUAL_TO,
              value: this.serviceItem.id,
            },
          ],
        });
      }
    }
  }

  protected canLoadServices(lastServices?: ServiceModel[]): boolean {
    if (this.serviceItem && lastServices) {
      return (
        lastServices.length === 0 ||
        lastServices.filter(
          (srv) =>
            srv.category?.id !== this.serviceItem.category?.id ||
            srv.id === this.serviceItem.id,
        ).length > 0
      );
    }

    return !lastServices;
  }
}
