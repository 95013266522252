import {
  FieldTypesConfigList,
  FileAcceptTypesEnum,
  FormBuilderInterface,
  FormTypeInterface,
} from '@ckmk/angular';
import { Injectable } from '@angular/core';
import { PostCategoryModel } from '../model/post.category.model';
import { environment } from '../../../../../../../../environments/environment';
import { DESCRIPTION_MAX_CHARS } from '../../../../../../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class PostCategoryFormType implements FormTypeInterface {
  public fields: FieldTypesConfigList = [];

  public constructor() {}

  public async configure(
    builder: FormBuilderInterface<PostCategoryModel>,
  ): Promise<void> {
    const data = builder.getData();

    if (data?.id) {
      builder.add({
        type: 'choice',
        name: 'active',
        label: 'Enable',
        required: false,
        help: 'Enable help',
        config: {
          type: 'checkbox-boolean',
          buttonType: 'button-only',
        },
      });
    }

    builder
      .add({
        type: 'file',
        name: 'cover',
        label: 'Cover',
        required: true,
        config: {
          accept: FileAcceptTypesEnum.IMAGES,
        },
      })
      .add({
        type: 'translation',
        name: 'title',
        label: 'Title',
        required: true,
        config: {
          type: 'text',
          key: data?.title?.key,
          domain: data?.title?.domain || 'posts_categories',
        },
      })
      .add({
        type: 'default',
        name: 'slug',
        label: 'Slug',
        required: false,
        help: 'Slug explain',
        config: {
          type: 'text',
        },
      })
      .add({
        type: 'translation',
        name: 'description',
        label: 'Description',
        required: true,
        config: {
          type: 'longtext',
          key: data?.description?.key,
          domain: data?.description?.domain || 'posts_categories',
          forceWysiwyg: true,
          max: DESCRIPTION_MAX_CHARS,
          licenseKey: environment.CKEDITOR5_LICENSE_KEY,
        },
      });
  }
}
