import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BreadcrumbItems,
  ComponentsModule,
  DashboardModule,
  dashboardPath,
  FormBuilderModule,
  FormPropErrorTranslatable,
  FormSubmitEmitterType,
  ItemNotFound,
  TabItems,
  TranslationModule,
  when,
} from '@ckmk/angular';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { PostService } from '../../../services/post.service';
import { PostModel } from '../../../classes/default/model/post.model';
import { PostFormType } from '../../../classes/default/form/post.form.type';

@Component({
  selector: 'app-post-manage',
  imports: [
    TranslationModule,
    DashboardModule,
    FormBuilderModule,
    ComponentsModule,
  ],
  templateUrl: './post-manage.component.html',
  styleUrl: './post-manage.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PostManageComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentInit
{
  @Input({
    required: false,
  })
  public set post(post: string | undefined) {
    if (post) {
      const currentValue = this._service.postQuery.current();

      if (
        !currentValue ||
        currentValue instanceof ItemNotFound ||
        currentValue.id !== post
      ) {
        this._service.fetchPost(post, {
          includeCategory: true,
          includePicture: true,
          includePictures: true,
          includeCover: true,
        });
      } else {
        this.postItem = when(this.isCopy(), currentValue.clone(), currentValue);

        this.initBreadcrumbItems(currentValue);
      }
    } else {
      this.initBreadcrumbItems();
    }
  }

  protected postItem?: PostModel;

  protected errors?: FormPropErrorTranslatable;

  protected tabs: TabItems = [
    {
      name: 'infos',
      label: 'Informations',
    },
    {
      name: 'shortDescription',
      label: 'Short description',
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'gallery',
      label: 'Gallery',
    },
  ];

  protected icons = {
    save: faFloppyDisk,
  };

  protected breadcrumbItems!: BreadcrumbItems;

  protected loading: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _service: PostService,
    private readonly _router: Router,
    protected readonly formType: PostFormType,
  ) {}

  public ngOnInit() {
    this._service.postQuery.onQuerySuccess((result) => {
      if (result instanceof ItemNotFound) {
        this._router
          .navigate([dashboardPath('/posts/')], {
            queryParams: { postNotFound: true },
          })
          .then();
      } else if (result) {
        this.postItem = when(
          this.isCopy(),
          result.clone(),
          when(this.isUpdate(), result),
        );

        this.initBreadcrumbItems(result);
      }
    });

    this._service.postMutation.onQuerySuccess((result, loading, callback) => {
      if (loading) {
        this.loading = true;

        this.errors = undefined;
      } else if (result instanceof PostModel) {
        if (this.isUpdate()) {
          if (callback) {
            callback(() => {
              this._service.postQuery.change(result);

              this.initBreadcrumbItems(result);

              this.loading = false;
            });
          }
        } else {
          this._service.fetchAll();

          this._router
            .navigate([dashboardPath('/posts/update'), result.id])
            .then();
        }
      }
    });

    this._service.postMutation.onQueryFailed((err, callback) => {
      if (err) {
        if (callback) {
          callback();
        }

        this.errors = err.details.messages;

        this.loading = false;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  public ngOnDestroy() {
    this._service.postQuery.unsubscribe();
    this._service.postMutation.unsubscribe(true);
  }

  public ngAfterContentInit() {}

  protected isCreate(): boolean {
    return !this.isUpdate() && !this.isCopy();
  }

  protected isCopy(): boolean {
    return this._router.url.includes('/copy/');
  }

  protected isUpdate(): boolean {
    return this._router.url.includes('/update/');
  }

  protected isReady(): boolean {
    if (this.isCopy() || this.isUpdate()) {
      return !!this.postItem;
    }

    return true;
  }

  protected initBreadcrumbItems(item?: PostModel): void {
    const breadcrumbItems: BreadcrumbItems = [
      {
        label: 'Posts',
        path: '/posts/list',
      },
      {
        label: 'Create',
      },
    ];

    if (!this.isCreate() && item) {
      if (this.isCopy()) {
        breadcrumbItems[1].label = 'Copy';
      } else {
        breadcrumbItems[1].label = 'Update';
      }

      breadcrumbItems.push({
        label: item.title?.toString(),
      });
    }

    this.breadcrumbItems = breadcrumbItems;
  }

  protected getSubTitle(): string {
    if (this.isCopy()) {
      return 'Copy post';
    } else if (this.isUpdate()) {
      return 'Update post';
    }

    return 'Create post';
  }

  protected onSave(event: FormSubmitEmitterType): void {
    const data: any = event.data[event.name];

    if (this.isUpdate()) {
      data.id = this.postItem?.id;
    }

    this._service.savePost(data, event.enable);
  }
}
