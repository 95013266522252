import { Route } from '@angular/router';
import { PostListComponent } from '../components/default/post-list/post-list.component';
import { PostShowComponent } from '../components/default/post-show/post-show.component';
import { PostManageComponent } from '../components/default/post-manage/post-manage.component';
import { PostCategoryListComponent } from '../components/category/category-list/post-category-list.component';
import { PostCategoryShowComponent } from '../components/category/category-show/post-category-show.component';
import { PostCategoryManageComponent } from '../components/category/category-manage/post-category-manage.component';

export default {
  path: 'posts',
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: PostListComponent,
      data: {
        permissions: ['PERMISSION_READ_POST'],
      },
    },
    {
      path: 'show/:post',
      component: PostShowComponent,
      data: {
        permissions: ['PERMISSION_READ_POST'],
      },
    },
    {
      path: 'create',
      component: PostManageComponent,
      data: {
        permissions: ['PERMISSION_CREATE_POST'],
      },
    },
    {
      path: 'update/:post',
      component: PostManageComponent,
      data: {
        permissions: ['PERMISSION_UPDATE_POST'],
      },
    },
    {
      path: 'copy/:post',
      component: PostManageComponent,
      data: {
        permissions: ['PERMISSION_COPY_POST'],
      },
    },
    {
      path: 'categories',
      children: [
        {
          path: '',
          redirectTo: 'list',
          pathMatch: 'full',
        },
        {
          path: 'list',
          component: PostCategoryListComponent,
          data: {
            permissions: ['PERMISSION_READ_POST_CATEGORY'],
          },
        },
        {
          path: 'show/:category',
          component: PostCategoryShowComponent,
          data: {
            permissions: ['PERMISSION_READ_POST_CATEGORY'],
          },
        },
        {
          path: 'create',
          component: PostCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_CREATE_POST_CATEGORY'],
          },
        },
        {
          path: 'update/:category',
          component: PostCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_UPDATE_POST_CATEGORY'],
          },
        },
        {
          path: 'copy/:category',
          component: PostCategoryManageComponent,
          data: {
            permissions: ['PERMISSION_COPY_POST_CATEGORY'],
          },
        },
        {
          path: '**',
          redirectTo: 'list',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: '**',
      redirectTo: 'list',
      pathMatch: 'full',
    },
  ],
} as Route;
